import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "react-query";
import Moment from "react-moment";
// import { errorToaster, successToaster } from "../../common/toaster";
import { logoutSuccess, updateFunds } from "../../store/actions";
import dms_login_logo from "../../assets/logo.png";
import styles from "./CampaignModal.module.css";
import CheckoutForm from "./CheckoutForm";

const funds = async (req) => {
  let query = req;
  let url = query
    ? `${process.env.REACT_APP_API_URL}/stripe`
    : `${process.env.REACT_APP_API_URL}/getBalance`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "content-Type": "application/json",
      session: `${localStorage.getItem("access_token")}`,
      Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
    },
  });

  if (!response.ok) {
    throw new Error("Something went wrong!");
  }

  return response.json();
};

const Header = () => {
  let location = useLocation();
  let dispatch = useDispatch();
  const history = useNavigate();

  const { data, funds: userFunds, PBKey } = useSelector((state) => state.login);

  const [navbarToggler, setNavbarToggler] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [viewStripe, setViewStripe] = React.useState(false);
  const [viewSuccessMessage, setViewSuccessMessage] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [stripePromise, setStripePromise] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [amtError, setAmtError] = useState(null);

  React.useEffect(() => {
    if (openModal) {
      setStripePromise(loadStripe(PBKey));
    }
  }, [openModal]);

  const {
    mutate,
    mutateAsync,
    isLoading: addFundLoading,
    error: addError,
  } = useMutation(funds);

  // React.useEffect(() => {
  //     var addFund = await mutateAsync({ "amount": amount });
  //     if (addFund.status === 1) {
  //         setClientSecret(addFund.responsedata.clientSecret);
  //         setIsLoading(false)
  //         setViewStripe(true)
  //     } else {
  //         setViewStripe(false)
  //     }
  // }, []);

  React.useEffect(() => {
    if (location?.pathname) {
      mutateAsync()
        .then((res) => {
          if (res.status === 1) {
            dispatch(
              updateFunds({
                funds: res.responsedata.getBalance,
                PBKey: res.responsedata.PBKey,
              })
            );
          } else if (res?.status === 0) {
            console.log("res err:", res);
          }
        })
        .catch((err) => {
          console.log("error Api ", err);
        });
    }
  }, [location?.pathname, mutateAsync]);

  function Modal({ children, shown, close }) {
    return shown ? (
      <div
        className={`modal-container ${styles.modalBackdrop}`}
        onClick={() => {
          // close modal when outside of modal is clicked
          close();
        }}
      >
        <div
          className={styles.sidebar_add_widget_form__1TGwy}
          onClick={(e) => {
            // do not close modal if anything inside modal content is clicked
            e.stopPropagation();
          }}
        >
          {/* <button onClick={close}>Close</button> */}
          {children}
        </div>
      </div>
    ) : null;
  }

  const handleUpdateData = async (e) => {
    e.preventDefault();

    if (amount > 0) {
      setIsLoading(true);
      var addFund = await mutateAsync({ amount: amount });
      if (addFund.status === 1) {
        setClientSecret(addFund.responsedata.clientSecret);
        setIsLoading(false);
        setViewStripe(true);
      } else {
        console.log("res err:", addFund);
        setViewStripe(false);
      }
    } else {
      setAmtError("Please Enter Positive Number !");
    }
  };

  const resetModal = () => {
    setOpenModal((oldState) => !oldState);
    setViewStripe(false);
    setViewSuccessMessage(false);
    setStripePromise("");
    setClientSecret("");
    setAmount(0);
    setAmtError(null);
  };

  const submitSuccess = () => {
    setViewSuccessMessage(true);
  };

  const addWidgetModal = () => {
    return (
      <Modal shown={openModal} close={() => resetModal()}>
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content add_widget_form">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {viewSuccessMessage ? "Success" : "Add Fund"}
              </h5>
            </div>
            <div className="modal-body">
              {viewSuccessMessage ? (
                <>
                  <div className="row mx-0">
                    <h6>{"Payment Added to your Account !"}</h6>
                  </div>
                  <center>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={(e) => resetModal(e)}
                    >
                      Close
                    </button>
                  </center>
                </>
              ) : (
                <form>
                  <div className="row mx-0">
                    <div className="col-md-12 mb-6">
                      <input
                        type="number"
                        autoFocus="autoFocus"
                        placeholder={"Enter Amount..."}
                        disabled={viewStripe}
                        class="form-control mb-3"
                        onChange={(e) => {
                          setAmount(e.target.value);
                          setAmtError(null);
                        }}
                        value={amount}
                      />
                      {amtError && <p>{amtError}</p>}
                    </div>
                  </div>
                  {clientSecret && stripePromise && viewStripe && (
                    <>
                      <Elements
                        stripe={stripePromise}
                        options={{ clientSecret }}
                      >
                        <CheckoutForm
                          resetModal={submitSuccess}
                          amount={amount}
                        />
                      </Elements>
                    </>
                  )}
                  {!viewStripe && (
                    <center>
                      <button
                        type="submit"
                        className="btn btn-primary mt-3"
                        disabled={addFundLoading}
                        onClick={(e) => handleUpdateData(e)}
                      >
                        {addFundLoading ? "Processing ... " : "Pay Now"}
                      </button>
                    </center>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  const addWidgetModalFunc = React.useMemo(
    () => addWidgetModal(),
    [
      openModal,
      amount,
      amtError,
      clientSecret,
      stripePromise,
      addFundLoading,
      isLoading,
      viewSuccessMessage,
    ]
  );

  const logout = () => {
    dispatch(logoutSuccess());
    history("/login");
    localStorage.clear();
  };

  const hideHeaders = () => {
    if (location.pathname !== "/login" && data) {
      return (
        <>
          <nav class="navbar navbar-expand-lg navbar-light py-2">
            <div class="container-fluid">              
              <Link to="/">
                <a class="navbar-brand" href="javascript:void(0)">
                  <img src={dms_login_logo} className="navbar-logo" width="197" alt="logo" />
                </a>
              </Link>              

              <button
                class={
                  navbarToggler ? "navbar-toggler" : "navbar-toggler collapsed"
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={navbarToggler ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={() => setNavbarToggler(!navbarToggler)}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <span className="analyticscls">
                Tele Stream Corp Analytics
                <small>
                  <br />
                  <Moment format="dddd MMM D, YYYY" withTitle>
                    {/* {this.state.cdate} */}
                  </Moment>
                </small>
              </span>
              <div
                class={
                  navbarToggler
                    ? "collapse navbar-collapse show"
                    : "collapse navbar-collapse"
                }
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav ms-auto">
                  {/* <li class="nav-item">
                    <Link
                      class={`nav-link ${
                        location?.pathname === "/" ? " active" : ""
                      }`}
                      aria-current="page"
                      to="/"
                    >
                      Dashboard
                    </Link>
                  </li> */}
                  <li class="nav-item">
                    <Link
                      class={`nav-link ${
                        location?.pathname === "/campaign" ? " active" : ""
                      }`}
                      to="/campaign"
                    >
                      Campaign
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class={`nav-link ${
                        location?.pathname === "/report-state" ? " active" : ""
                      }`}
                      to="/report-state"
                    >
                      Geo Activity Map
                      {/* Map */}
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class={`nav-link ${
                        location?.pathname === "/report" ? " active" : ""
                      }`}
                      to="/report"
                    >
                      Analytics
                    </Link>
                  </li>
                  {/* <li class="nav-item">
                    <Link
                      class={`nav-link ${
                        location?.pathname === "/ledgerbook" ? " active" : ""
                      }`}
                      to="/ledgerbook"
                    >
                      Ledger
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class={`nav-link`}
                      onClick={() => setOpenModal((oldState) => !oldState)}
                    >
                      Add Funds
                    </Link>
                  </li>
                  <li class="nav-item align-self-center">
                    <div class="profile-pic">
                      <span> {userFunds}</span>
                    </div>
                  </li> */}

                  <li class="nav-item align-self-center">
                    <Link to={"/useredit"} state={data?.userdata}>
                      <div class="profile-pic">
                        <span className="me-1">
                          <i className="fa fa-user"></i>
                        </span>
                        <span>{data?.userdata?.username}</span>
                      </div>
                    </Link>

                    {location.pathname === "/login" ? (
                      <Link to="/login">
                        <a class="nav-link">
                          <i class="fas fa-power-off"></i> Login
                        </a>
                      </Link>
                    ) : (
                      <a
                        class="nav-link logout-btn"
                        href="javascript:void(0)"
                        onClick={() => logout()}
                      >
                        <i class="fas fa-power-off"></i>
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          {addWidgetModalFunc}
        </>
      );
    } else {
      return null;
    }
  };

  return hideHeaders();
};
export default Header;
