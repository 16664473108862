import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import DashBoard from "../components/DashBoard/DashBoard";
import Login from "../components/Login/Login";
import Protected from "./Protected";
import { MainLoader } from "./Loader";
import Campaign from "../components/Campaign/Campaign";
import GeoActivity from "../components/Geo-Activity/Geo_Activity";
import AddCompaign from "./../components/Campaign/addCompaign";
import Analytics from "../components/Analytics/Analytics";
import ChangePassword from "../components/Profile/ChangePassword";
import CampaignDetails from "../components/Campaign/CampaignDetails";
import DynamicPrice from "../components/Campaign/DynamicPrice";
import DayWiseCap from "../components/Campaign/DayWiseCap";
import Filter from "../components/Campaign/Filter";
import ClickReport from "../components/Campaign/ClickReport";
import Apispecs from "../components/ApiSpecs/apispecs";
import DirectLogin from "../components/DirectLogin/DirectLogin";
// import LedgerBook from "../components/Ledgerbook/Ledgerbook";

function RoutesURL() {
  let load = MainLoader();
  return (
    <Suspense fallback={load}>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <Protected>
              <DashBoard />
            </Protected>
          }
        />
        <Route
          path="/campaign"
          element={
            <Protected>
              <Campaign />
            </Protected>
          }
        />
        <Route
          path="/report-state"
          element={
            <Protected>
              <GeoActivity />
            </Protected>
          }
        />
        <Route
          path="/addCampaign"
          element={
            <Protected>
              <AddCompaign />
            </Protected>
          }
        />
        <Route
          path="/report"
          element={
            <Protected>
              <Analytics />
            </Protected>
          }
        />

        {/* <Route
          path="/ledgerbook"
          element={
            <Protected>
              <LedgerBook />
            </Protected>
          }
        /> */}
        <Route
          path="/useredit"
          element={
            <Protected>
              <ChangePassword />
            </Protected>
          }
        />
        <Route
          path="/campaignads"
          element={
            <Protected>
              <CampaignDetails />
            </Protected>
          }
        />
        <Route
          path="/dynamicprice/:userId"
          element={
            <Protected>
              <DynamicPrice />
            </Protected>
          }
        />
        <Route
          path="/daywisecap/:userId"
          element={
            <Protected>
              <DayWiseCap />
            </Protected>
          }
        />
        <Route
          path="/filter/:userId"
          element={
            <Protected>
              <Filter />
            </Protected>
          }
        />
        <Route
          path="/clickreport"
          element={
            <Protected>
              <ClickReport />
            </Protected>
          }
        />
        <Route
          path="/apispecs/:id/:token/:display_name"
          element={
            <Protected>
              <Apispecs />
            </Protected>
          }
        />
        <Route exact path="/directLogin/:id" element={<DirectLogin />} />
      </Routes>
    </Suspense>
  );
}

export default RoutesURL;
