import axios from "axios";
import {
  login,
  loginError,
  loginSuccess,
  messageText,
} from "../store/actions";

export class AuthenticationService {
  static Login = (data) => {
    return (dispatch) => {
      dispatch(login());
      axios.post(`${process.env.REACT_APP_API_URL}/login`, data, {
        headers: {
          "content-Type": "application/json",
          Authkey: `${process.env.REACT_APP_API_AUTH_KEY}`,
        },
      }).then((response) => {
        localStorage.removeItem("access_token");
        if (response.data.status === 1) {
          localStorage.setItem("access_token", response.data.responsedata.session);
          dispatch(messageText({
            type: "success",
            message: response.data.message
          }));
          dispatch(loginSuccess(response.data.responsedata));
        } else {
          dispatch(loginError(response.data.message));
        }
      }).catch((error) => {
        console.log("Login error");
        localStorage.clear();
        dispatch(loginError("Login error"));
      });
    };
  };
}
